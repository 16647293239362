import React from "react";
import './topbar.css';

function Topbar()  {
    return(
        <div className={`top-bar navbar-fixed-top`}>
            <HomeButton/>
        </div>
    )
}

function HomeButton(){

    const scrollToTop = () => {
        window.scrollTo({
            top: 0, // Scroll to the top of the page
            behavior: 'smooth', // Smooth scrolling behavior
        });
    };

    return (
        <div class="dropdown">
            <a href='javascript: void(0)' class="btn btn-primary dropdown-toggle" data-toggle="tooltip"  title="Go to top of page" className='site-home' onClick={scrollToTop}>
                Loudon Hurt
            </a>
        </div>
    )
}


export default Topbar;