import React from "react";
import './App.css';
import Topbar from "../topbar_component/topbar";
import Header from "../header-component/header";
import ExperienceAndSkills from "../experience-and-skills-component/experience_and_skills";
import HeadshotAndDescription from "../headshot_description_component/headshot_description";
import Teamwork from "../teamwork-component/teamwork";
import SocialsAndContact from "../socials-and-contact-component/socials";

function App() {

  return (
        <div className="app-container">
            <div className={`main-content`}>
                <Topbar />
                <Header />
                <hr/>
                <div className="about-me-section" id="about-me">
                    <h1 className="section-header">About Me</h1><br/>
                    <HeadshotAndDescription />
                </div>
                <hr className={"text-decoration-line-through"}/>
                <div className="teamwork-section">
                    <br/>
                    <Teamwork />
                </div>
                <hr className={"text-decoration-line-through"}/>
                <div className="experience-section" id="experience">
                    <h1 className={"section-header"}>Experience and Skills</h1><br/>
                    <ExperienceAndSkills />
                </div>
                <hr className={"text-decoration-line-through"}/>
                <div className="socials-section" id="socials">
                    <h1 className={"section-header"}>Socials and Contact</h1><br/>
                    <SocialsAndContact/>
                </div>
                <footer className="bg-dark text-center text-white copyright">
                    <div className="text-start">&copy; Loudon Hurt 2023</div>
                </footer>
            </div>
        </div>
  );
}

export default App;
