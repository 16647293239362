import React from "react";
import './experience_and_skills.css';
import arms from '../images/NicePng_arms-logo-png_7675869.png'
import wrestling from '../images/IMG_4169.JPG'
import frameworks from '../images/angular-vs-react.png'
import matrix from '../images/NvL.gif'



function ExperienceAndSkills(){

    return(
        <div className={"section-container"}>
            <div className={"card-container"}>
                <div className={"card bg-dark text-white experience-card"}>
                    <img className="card-img-top bg-white" src={arms} alt={"ARMS Software logo"}/>
                    <p className="card-body card-text">Intern at ARMS Software working in web development.  Relevant skills here include implementing Angular framework and backend experience in creating RESTful services.</p>
                </div>
                <div className={"card bg-dark text-white experience-card"}>
                    <img className="card-img-top" src={wrestling} alt={"Loudon Hurt wrestling at US Open 2021"}/>
                    <p className="card-body card-text">Work ethic, integrity and drive to succeed and improve are all skills I learned in my time wrestling through college.</p>
                </div>
                <div className={"card bg-dark text-white experience-card"}>
                    <img className="card-img-top" src={frameworks} alt={"Angular and React Logos"}/>
                    <p className="card-body card-text">I have the most expertise in web design and web site building.  In particular I am well versed in Angular, React, and
                    other similar frameworks.    </p>
                </div>
                <div className={"card bg-dark text-white experience-card"}>
                    <img className="card-img-top" src={matrix} alt={"Green text falling down a screen in columns"}/>
                    <p className="card-body card-text">I bring many skills such as problem solving ability, computational ability, diligence, as well as a willingness to learn and grow
                    that helps me evolve on the job.</p>
                </div>
            </div>
        </div>
    )
}

export default ExperienceAndSkills;