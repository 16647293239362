import React from "react";
import './github.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function Github(){
    return(
        <div className="github-container">
            <a href={"https://github.com/loudon-hurt-1"} className="github-link">
                <FontAwesomeIcon className="github-logo" icon="fa-brands fa-github"/>
            </a>
        </div>
    )
}

export default Github;