import React from "react";
import './headshot_description.css';
import headshot from "../images/IMG_1406.jpg";


function HeadshotAndDescription(){


    return (
            <div className="section-container">
                <div className="card text-white bg-dark card-container headshot-card ">
                    <div className="headshot card-body">
                        <h1 className="greeting card-title">Hi I'm Loudon!</h1>
                        <img src={headshot} className="headshot-picture card-img rounded-circle" alt="Picture of Loudon Hurt"/>
                        <p className="description card-text">
                            I am a fourth year software engineer at the University of Virginia.  I have experience in web design using
                            frameworks such as Angular and React.  I am also proficient in SQL, Java, and Python.  I have excellent
                            problem-solving and abstract thinking skills, as well as pride myself on my ability to figure out things on my
                            own.  I am looking for full-time opportunities after I graduate in Spring 2024.
                        </p>
                    </div>
                </div>
            </div>
    );
}

export default HeadshotAndDescription;