import React from "react";
import './linkedin.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function LinkedIn(){
    return(
        <div className="linkedin-container">
            <a href='https://www.linkedin.com/in/loudon-hurt-275b6a285/' className="linkedin-link">
                <FontAwesomeIcon className="linkedin-logo" icon="fa-brands fa-linkedin"/>
            </a>
        </div>
    )
}

export default LinkedIn;