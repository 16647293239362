import React from "react";
import Carousel from 'react-bootstrap/Carousel'
import collab1 from "../images/collaboration1.png";
import wrestlingTeamTitle from "../images/IMG_0769.JPG";
import collab2 from "../images/collaboration2.png";
import postUSOpen from "../images/IMG_1681.JPG";

function Teamwork(){

    return (
        <div className="other-section-container">
            <div className="card text-white bg-dark carousel-container">
                <div className="card-body">
                    <Carousel className="card-img-top">
                        <Carousel.Item>
                            <img src={collab1} alt="Teamwork on a CS Project" className="w-100 h-100 carousel-picture"/>
                        </Carousel.Item>

                        <Carousel.Item>
                            <img src={wrestlingTeamTitle} alt="Helping coach a team towards a conference title" className="w-100 h-100 carousel-picture"/>
                        </Carousel.Item>

                        <Carousel.Item>
                            <img src={collab2} alt="Intra-work session team picture" className="w-100 h-100 carousel-picture"/>
                        </Carousel.Item>

                        <Carousel.Item>
                            <img src={postUSOpen} alt="Post US Open Team Meal" className="w-100 h-100 carousel-picture"/>
                        </Carousel.Item>
                    </Carousel>
                    <p className="card-text">
                        I am all about collaboration and working towards a common goal.  Whether it be in the professional and academic world, or
                        working with others to achieve something in the physical and competitive sense, I love helping and leading people.
                    </p>
                </div>
            </div>

        </div>
    )
}

export default Teamwork;