import React from "react";
import Github from "../github-component/github";
import Linkedin from "../linkedin-component/linkedin";
import Inquiry from "../send-inquiry-component/inquiry";

function SocialsAndContact(){
    return(
        <div className="section-container">
            <div className="card-container">
                <Github/>
                <Linkedin/>
                <Inquiry/>
            </div>
        </div>
    )
}

export default SocialsAndContact;