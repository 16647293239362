import React from "react";
import './inquiry.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function Inquiry(){

    return(
            <div className="card bg-dark text-white email-container ">
                <div className="card-body">
                    <p className="card-text">
                        To contact, email me at <strong>loudonhurt@gmail.com</strong> or click the icon below to send one in another tab.
                    </p>
                </div>
                <a href="mailto:loudonhurt@gmail.com" target="_blank" rel="noreferrer"  className="email-button">
                    <FontAwesomeIcon className="email-icon" icon="fa fa-envelope"/>
                </a>
            </div>
    )
}

export default Inquiry;