import React from "react";
import './header.css';
import gif_background from '../images/giphy.gif';

function Header(){
    return(
        <div className={`header`}>
            <img src={gif_background} className="gif" alt="Pixelated colorful background"/>
            <div className="text-overlay">
                <p className="bottom-right-text">Loudon Hurt: Software Engineering</p>
            </div>
        </div>
    )
}

export default Header;